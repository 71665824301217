
  /* ----------------------------------------
      Imports
  ---------------------------------------- */

  import { Component, Vue } from 'vue-property-decorator';
  import { setTitle, handle429 } from '@util/util.ts';
  import { app } from '@config';
  import ChevronLeftSvg from '@svg/ChevronLeft.vue';
  import TextBlock from '@part/blocks/TextBlock.vue';
  import QuoteBlock from '@part/blocks/QuoteBlock.vue';
  import ImageBlock from '@part/blocks/ImageBlock.vue';
  import ImageInlineBlock from '@part/blocks/ImageInlineBlock.vue';
  import ImageTextTitleBlock from '@part/blocks/ImageTextTitleBlock.vue';
  import ClockSvg from '@svg/ClockMultiColor.vue';
  import LinkToContentBlock from '@part/blocks/LinkToContentBlock.vue';
  import ImagesBlock from '@part/blocks/ImagesBlock.vue';
  import AccordionBlock from '@part/blocks/AccordionBlock.vue';

  import axios from 'axios';

  /* ----------------------------------------
      Component
  ---------------------------------------- */

  @Component({
    components: {
        ChevronLeftSvg,
        ClockSvg,
        TextBlock,
        QuoteBlock,
        LinkToContentBlock,
        ImageBlock,
        ImageInlineBlock,
        ImageTextTitleBlock,
        ImagesBlock,
        AccordionBlock
    },
    data() {
      return {
        pageData: null,
        backLink: {
            text: 'Ga naar overzicht',
            url: '/articles'
        }
      };
    },
    props: {
      id: {
        required: true,
        default: 0
      }
    },
    methods: {
    },
    mounted() {
      let $this = this;
      axios
        .get(`${app.api.api}/article/${$this.id}`)
        .then(response => {
          $this.pageData = response.data.data;
          setTitle(response);
        })
        .catch(error => {
          console.log(`🔥${error}🔥`);
          handle429(this, error);
        });
    }
  })

  /* ----------------------------------------
      Export
  ---------------------------------------- */
  export default class ArticleDetail extends Vue {}
  