
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  props: {
    data: {
      type: Object,
      required: true
    },
    collection: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    isMobile() {
      let breakpoint = '960';
      if (window.innerWidth < breakpoint) {
        return true;
      }
      return false;
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ImageTextTitleBlock extends Vue { }
